import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
  IoMdOpen,
  IoIosArrowForward,
  IoIosCloudDone,
  IoIosSync,
  IoIosFlash,
} from "react-icons/io";
import styled from "styled-components";

import Image from "../components/image";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  CustomerCard,
  ImageGraphic,
  Breadcrumb,
  ConnectionButton,
} from "../components/site/";

import PayHeroLogo from "../images/logos/PayHero/PayHero_Full.svg";
import XeroLogo_Single from "../images/logos/Xero/Xero_Blue_Single.svg";
// import XeroConnectedLogo from "../images/logos/Xero/Xero_ConnectedAppLogo_Dark.png";
import XeroConnectedLogo_White from "../images/logos/Xero/Xero_ConnectedAppLogo_Light.png";
import PayHeroPlusXero from "../images/logos/PayHero/PayHeroPlusXero.svg";

const NumberBadge = styled.div`
  background-color: ${(props) => props.theme.colours.grey};
  color: ${(props) => props.theme.colours.dark};
  border-radius: 35px;
  width: 100%;
  height: 100%;
  max-width: 35px;
  max-height: 35px;
  line-height: 35px;
  display: block;
  font-weight: 700;
  text-align: center;
  user-select: none;
  margin-top: -7px;
`;

const Xero = (props) => {
  const customerData = useStaticQuery(graphql`
    query {
      allContentfulCustomerStory(
        filter: { name: { in: ["Dignity", "The Source Bulk Foods"] } }
      ) {
        edges {
          node {
            name
            url
            heroQuote
            industry
            banner {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
            logo {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo
        title="Xero + PayHero Integration | Accounting & Payroll"
        description="Automatically send payroll transactions to your Xero accounting software. Enjoy faster & more accurate payroll with the Xero add-on."
        pathname={props.location.pathname}
      />
      <Breadcrumb
        parentPage="Integrations"
        parentPageLink="/add-ons"
        currentPage="Xero"
      />
      <Container>
        <Wrapper>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={30} centerOnMobile>
              <img
                src={PayHeroPlusXero}
                alt="PayHero + Xero Integration"
                height="40"
              />
              <Box stackGap={10}>
                <h1 className="-fontLarge">
                  <span className="-fontPrimary">Keep your accounts</span>
                  <br className="hideOnMobile" /> up to date, always
                </h1>
                <h4>
                  Automatically send payroll transactions to your Xero
                  accounting software. Enjoy the convenience of advanced cost
                  tracking and fast reconciliation with no data entry.
                </h4>
              </Box>
              <FlexButtons>
                <ConnectionButton connection="Xero" product="PayHero" />
                <Button to="/signup" className="primary -lg">
                  Sign up with email
                </Button>
              </FlexButtons>
              <a
                className="link-floating -centerOnMobile"
                target="_blank"
                href="https://www.xero.com/signup/?xtid=x30payhero"
              >
                Try Xero for Free{" "}
                <IoMdOpen css={{ top: "2px", marginLeft: "5px" }} />
              </a>
            </Box>
            <Box size={50} css={{ padding: "40px" }}>
              <ImageGraphic variant="1" />
              <Image
                filename="PageHeader_Xero.png"
                alt="PayHero In-App | Xero Connected App | PayHero"
                rounded
                addShadow
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={40} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoIosFlash />
                <br />
                Fast, accurate payroll
              </h3>
              <p>
                Spend less time getting your payroll right with online
                timesheets, employee self-service, expenses, leave management
                and zero touch payday filing.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosSync />
                <br />
                Automatically update your books
              </h3>
              <p>
                Our slick integration lets you automatically send your payroll
                transactions to Xero and apportion costs to different account
                codes or tracking categories.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosCloudDone />
                <br />
                Better together
              </h3>
              <p>
                Connecting the best <Link to="/payroll">payroll</Link> and
                accounting apps with powerful integration gives you the software
                toolkit you need to scale your business.
              </p>
            </Box>
          </Row>
          <hr />
          <Image
            alt="Xero Integration | PayHero"
            filename="XeroPayHeroGraphic.png"
            addShadow
          />
          <Row stackGap={40} className="-textCenter">
            <Box size={33}>
              <h3>Save time</h3>
              <p>
                Automatically send payroll invoices from your payroll to your
                accounting system when you finalise a pay, so you can track them
                in your Chart of Accounts.
              </p>
            </Box>
            <Box size={33}>
              <h3>Keep on top of your staff costs</h3>
              <p>
                See your wage costs in real time so you can stay across seasonal
                variations and ensure you’ve got the right number of staff
                rostered on for busy and quiet times.
              </p>
            </Box>
            <Box size={33}>
              <h3>Works the way you do</h3>
              <p>
                Keep it simple or automatically apply different payroll costs to
                Xero account codes or tracking categories, giving you detailed
                cost tracking with no data entry.
              </p>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <Box stackGap={40}>
            <h2 className="-textCenter">
              See what Xero customers have to say about working with us
            </h2>
            <Image
              alt="Xero & PayHero Reviews | Integration"
              filename="XeroIntegration_FiveStar.png"
              maxWidth={300}
              centerImage
            />
            <a
              className="link-floating -center"
              href="https://apps.xero.com/nz/app/payhero/reviews"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read Reviews <IoMdOpen css={{ top: "2px", left: "2px" }} />
            </a>
          </Box>
          <Row stackGap={30}>
            {customerData.allContentfulCustomerStory.edges.map((edge, i) => {
              return (
                <Box size={50} key={i}>
                  <CustomerCard
                    slug={edge.node.url}
                    banner={edge.node.banner.gatsbyImageData}
                    logo={edge.node.logo.gatsbyImageData}
                    quote={edge.node.heroQuote}
                    industry={edge.node.industry}
                    company={edge.node.name}
                  />
                </Box>
              );
            })}
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <h2 className="-textCenter">How does the Xero integration work?</h2>
          <Row stackGap={80} alignCenter>
            <Box size={50} stackGap={50} centerOnMobile>
              <Row alignCenter stackGap={25}>
                <NumberBadge>1</NumberBadge>
                <div>
                  <h3>Connect to Xero</h3>
                  <p>
                    Choose how to send your payroll transactions to Xero, map
                    your Pay Items to the Chart of Accounts and set up tracking
                    categories (if you need detailed job costing).
                  </p>
                </div>
              </Row>
              <Row alignCenter stackGap={25}>
                <NumberBadge>2</NumberBadge>
                <div>
                  <h3>Enjoy the automation</h3>
                  <p>
                    Every time you process your payroll the details of the
                    transaction are automatically posted to Xero as a draft
                    invoice for your approval and reconciliation.
                  </p>
                </div>
              </Row>
              <Row alignCenter stackGap={25}>
                <NumberBadge>3</NumberBadge>
                <div>
                  <h3>Reconcile payments</h3>
                  <p>
                    After you run each pay in PayHero, pop over into Xero to
                    reconcile the payments. It couldn’t be easier!
                  </p>
                </div>
              </Row>
              <p>
                For more information visit{" "}
                <a
                  href="https://support.payhero.co.nz/hc/en-us/articles/360002749415-Xero-Integration"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Integrating PayHero with Xero
                </a>
                .
              </p>
            </Box>
            <Box size={50}>
              <Image
                alt="PayHero & Xero Integration"
                filename="XeroIntegration_PayHero.png"
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <Row stackGap={60} alignCenter>
            <Box size={50}>
              <img
                className="-center"
                src={PayHeroLogo}
                alt="PayHero | Online Payroll Made Super Easy"
                height="80"
              />
            </Box>
            <Box size={50} stackGap={30}>
              <div>
                <h2>About PayHero</h2>
                <h4>
                  PayHero is the perfect <Link to="/payroll">payroll</Link> for
                  any size business with employees that work variable hours.
                </h4>
              </div>
              <ul>
                <li>
                  Accurately capture employee hours with online timesheets,
                  employee mobile app or photo clock in and out.
                </li>
                <li>
                  Automatically calculate pay and holiday entitlements from
                  online timesheets and work patterns.
                </li>
                <li>
                  Connect to payday filing and we’ll send your PAYE information
                  to IRD.
                </li>
                <li>
                  Allow employees to request leave through the app that managers
                  can approve from an email.
                </li>
                <li>Expert phone and email support from our NZ-based team.</li>
              </ul>
              <Link className="link-arrow" to="/signup">
                Get Started for Free <IoIosArrowForward />
              </Link>
            </Box>
          </Row>
          <Row stackGap={60} alignCenter mobileReverse>
            <Box size={50} stackGap={30}>
              <div>
                <h2>About Xero</h2>
                <h4>
                  Xero is world-leading online accounting software built for
                  small business.
                </h4>
              </div>
              <ul>
                <li>
                  Get a real-time view of your cashflow. Log in anytime,
                  anywhere on your Mac, PC, tablet of phone to get a real-time
                  view of your cash flow. It’s small business accounting
                  software that’s simple, smart and occasionally magical.
                </li>
                <li>
                  Run your business on the go. Use our mobile app to reconcile,
                  send invoices, or create expense claims - from anywhere.
                </li>
                <li>
                  Get paid faster with online invoicing. Send online invoices to
                  your customers - and get updated when they’re opened.
                </li>
                <li>
                  Reconcile in seconds. Xero imports and categorises your latest
                  bank transactions. Just click ok to reconcile.
                </li>
              </ul>
              <p>
                Find out more or try{" "}
                <a
                  href="https://www.xero.com/?xtid=payhero"
                  hrefLang="en"
                  media="all"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Xero Accounting Software
                </a>{" "}
                for free.
              </p>
            </Box>
            <Box size={50}>
              <img
                className="-center"
                src={XeroLogo_Single}
                alt="Xero Integration | PayHero"
                height="200"
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container className="secondary -textCenter">
        <Wrapper>
          <Box stackGap={50}>
            <div>
              <h2>
                Connect PayHero & Xero today
                <br />
                <span className="-fontLight">
                  Automatically send payroll transactions to your Xero
                  accounting software.
                </span>
              </h2>
            </div>
            <FlexButtons justify="center">
              <ConnectionButton connection="Xero" product="PayHero" dark />
              <Button to="/signup" className="primary -lg">
                Sign up with email
              </Button>
            </FlexButtons>
            <a
              className="link-floating -center"
              target="_blank"
              href="https://www.xero.com/signup/?xtid=x30payhero"
            >
              Try Xero for Free{" "}
              <IoMdOpen css={{ top: "2px", marginLeft: "5px" }} />
            </a>
            <img
              className="-center"
              css={{ maxWidth: "200px" }}
              src={XeroConnectedLogo_White}
              alt="PayHero | Xero Connected App"
            />
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Xero;
